@import url('https://fonts.googleapis.com/css2?family=Geo:ital@0;1&family=Lexend:wght@100..900&family=Ubuntu+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');



.lexend-300 {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

.lexend-400 {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

